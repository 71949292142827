import { logger } from '@/core/features/logger/logger';

export default function clone<T>(arg: T): T {
    try {
        if (typeof arg !== 'object') {
            throw `Could not apply structuredClone to non-object type ${typeof arg}`;
        }

        if (typeof structuredClone === 'function') {
            return structuredClone(arg);
        }

        return JSON.parse(JSON.stringify(arg));
    } catch (error) {
        logger.error(`Error cloning: ${error}`);
        return arg;
    }
}
